import countries from "../countries.js"

export default {
    getCountries (uppercase) {
        return Object.values(countries).map(c => uppercase ? c.toUpperCase() : c)
    },

    isValidPostalCode (code, country) {
        if (!code) {
            return true
        }
        const val = country ? country.toUpperCase() : ''
        let ok = false
        switch (val) {
            case 'ALLEMAGNE':
            case 'ESPAGNE':
            case 'FRANCE':
            case 'HONGRIE':
            case 'ITALIE':
                ok = /^\d{5}$/.test(code)
                break
            case 'AUTRICHE':
            case 'BELGIQUE':
            case 'BULGARIE':
            case 'DANEMARK':
            case 'SUISSE':
                ok = /^\d{4}$/.test(code)
                break
            case "CANADA":
                ok = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/.test(code)
                break
            case "CROATIE":
                ok = /^HR-\d{5}$/.test(code)
                break
            case 'ETATS-UNIS':
                ok = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(code)
                break
            case 'GRÈCE':
                ok = /^\d{3} \d{2}$/.test(code)
                break
            case 'IRLANDE':
                ok = /^([A-Z][0-9][0-9W]) \d{4}$/.test(code)
                break
            case "LUXEMBOURG":
                ok = /^L-\d{4}$/.test(code)
                break
            case 'PAYS-BAS':
                ok = /^\d{4} \w{2}$/.test(code)
                break
            case 'PORTUGAL':
                ok = /^\d{4}-\d{3}$/.test(code)
                break
            case 'ROYAUME-UNI':
                ok = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(code)
                break
            case 'SUÈDE':
                ok = /^SE-\d{5}$/.test(code)
                break
            default:
                ok = true
        }

        return ok || "Le code postal saisi n'est pas valide pour ce pays"
    },
}
